import { CHAT_CHANNEL, IChatMessage } from '@libs/interfaces';
import { createAction, props } from '@ngrx/store';
import { GIF_TYPE } from './model'

export const setChannel = createAction(
  '[Chat] Set channel',
  props<{ channel: CHAT_CHANNEL }>()
);
export const addMessage = createAction(
  '[Chat] Add message',
  props<{ message: IChatMessage }>()
);
export const addMessages = createAction(
  '[Chat] Add messages',
  props<{ messages: IChatMessage[] }>()
);
export const addMention = createAction(
  '[Chat] Add mention',
  props<{ mention: string }>()
);
export const toggleMessages = createAction(
  '[Chat] Toggle'
);
export const setGiftType = createAction(
  '[Chat] Set gifts type',
  props<{ topic: GIF_TYPE }>()
);
export const setGiftOffset = createAction(
  '[Chat] Set gifts offset',
  props<{ offset: number }>()
);
export const addGiftCollection = createAction(
  '[Chat] add gifts',
  props<{ collection: any[] }>()
);
export const setGif = createAction(
  '[Chat] Select gift',
  props<{ gif: any }>()
);
export const setEmogiOpen = createAction(
  '[Chat] Set Emogi Open',
  props<{ value: boolean }>()
);
export const toggleEmogiOpen = createAction(
  '[Chat] Toggle Emogi'
);
export const setGifOpen = createAction(
  '[Chat] Set Gif Open',
  props<{ value: boolean }>()
);