<div class="wrapper" [ngClass]="{ close: exit() }">
  <div class="header">
    <icon name="ph:telegram-logo-bold" size="24px" color="telegram-color"></icon>
    <div class="title">
      <h2 class="title-text">{{ 'TG.CONVERSE_WITH_US' | translate }}</h2>
    </div>
    <icon name="ph:x-bold" (click)="close()" size="24px"></icon>
  </div>

  <div class="middle">
    <div class="logo">
      <img src="assets/logos/telegram_logo.webp" alt="Telegram Logo" />
    </div>
    <p class="text">{{ 'TG.SUPPORT_NOW_WITH_TELEGRAM_BOT' | translate }}</p>
    <ul class="benefits-list">
      <li class="benefit-item">
        <icon name="ph:check-circle-bold" size="24px" color="telegram-color"></icon>
        <span class="benefit-text">{{ 'TG.QUICK_AND_RELIABLE_SUPPORT' | translate }}</span>
      </li>
      <li class="benefit-item">
        <icon name="ph:check-circle-bold" size="24px" color="telegram-color"></icon>
        <span class="benefit-text"
          >{{ 'TG.AVAILABILITY_24_7' | translate }} - {{ 'TG.SCHEDULER_RESPONDED_BY_BOT' | translate }}</span
        >
      </li>
      <li class="benefit-item">
        <icon name="ph:check-circle-bold" size="24px" color="telegram-color"></icon>
        <span class="benefit-text">{{ 'TG.SCHEDULER_AVAILABLE' | translate }}</span>
      </li>
      <li class="benefit-item">
        <icon name="ph:check-circle-bold" size="24px" color="telegram-color"></icon>
        <span class="benefit-text">{{ 'TG.PERSONALIZED_ASSISTANCE' | translate }}</span>
      </li>
    </ul>
    @if (loggedAndConnected()) {
      <button class="enter-button" (click)="onConnected()">
        {{ 'TG.ENTER' | translate }} <icon name="ph:arrow-right-bold"></icon>
      </button>
    } @else if (logged()) {
      <app-provider-telegram (onconnected)="onConnected()" />
    } @else {
      <app-telegram-sign-button (onlogged)="onConnected()" />
    }
  </div>
  <div class="footer">
    @if (loggedAndConnected()) {
      <div class="logged-connected">
        <p class="text">{{ 'TG.ALREADY_CONNECTED' | translate }}</p>
        <icon name="ph:check-bold" color="primary-color"></icon>
      </div>
    }
  </div>
</div>
