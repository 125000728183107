import { CHAT_CHANNEL, IChatMessage } from "@libs/interfaces";
export enum GIF_TYPE {
  TRENDING = 'trending',
  LAUGHING = 'laughing',
  SAD = 'sad',
  LOVE = 'love',
  REACTION = 'reaction',
  DOGS = 'dogs',
}
export interface IGifts {
  offset: number
  type: GIF_TYPE
  collection: any[]
  selected: any
}
export interface IChatState {
  channels: CHAT_CHANNEL[]
  channel: CHAT_CHANNEL
  messages: IChatMessage[]
  active: boolean,
  gifts: IGifts
  gif: any
  emojiOpen: boolean
  gifOpen: boolean
  mention: string
}
