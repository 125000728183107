import { Component, computed, ElementRef, output, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@libs/app.services/landing/auth.service';
import { UserStoreService } from '@libs/app.services/store/user-store.service';
import { TelegramSignButtonComponent } from '@libs/stand-alone-components/sign/components/providers/telegram-sign-button/telegram-sign-button.component';
import { ProviderTelegramAskComponent } from '@libs/stand-alone-components/account/profile/provider-telegram-ask/provider-telegram-ask.component';
import { TelegramService } from '@libs/app.services/account/telegram.service';
@Component({
  selector: 'app-telegram-chat-warn',

  imports: [CommonModule, IconComponent, TranslateModule, ProviderTelegramAskComponent, TelegramSignButtonComponent],
  templateUrl: './telegram-chat-warn.component.html',
  styleUrl: './telegram-chat-warn.component.scss',
})
export class TelegramChatWarnComponent {
  element = viewChild<ElementRef>('element');
  onclose = output<void>();
  logged = computed(() => this.authService.logged());
  loggedAndConnected = computed(() => this.logged() && this.userStoreService.isTelegramProviderAdded());
  exit = signal(false);
  constructor(
    private authService: AuthService,
    private userStoreService: UserStoreService,
    private telegramService: TelegramService,
  ) {}

  onConnected() {
    this.goBot();
    this.close();
  }
  close() {
    setTimeout(() => {
      this.onclose.emit();
    }, 300);
    this.exit.set(true);
  }
  private goBot() {
    this.telegramService.goBot();
  }
}
